import { createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import {
  FETCH_STATUS_IDLE, FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_ERROR,
} from '../../../utils/request/status'

const calculateAge = (date) => {
  const currentDate = dayjs()
  return currentDate.diff(dayjs(date), 'year')
}

const initialState = {
  get_all_menu_type_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  get_all_menu_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  get_menu_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  create_menu_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  edit_menu_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  delete_menu_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  get_sub_menu_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  edit_sub_menu_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  create_sub_menu_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  delete_sub_menu_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  get_customize_menu_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  edit_customize_menu_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  create_customize_menu_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  delete_customize_menu_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  get_choice_menu_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  edit_choice_menu_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  create_choice_menu_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  delete_choice_menu_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  create_choice_value_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  edit_choice_value_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  delete_choice_value_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  get_sub_menu_customize_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  get_selected_choice_menu_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  menuType: [],
  menu_list: [],
  menuID: undefined,
  menuData: undefined,
  subMenuData: undefined,
  customizeMenuData: undefined,
  choiceMenuData: undefined,
  subMenuCustomizeData: undefined,
  selectChoiceData: undefined,
}

const userSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    getAllMenuTypeRequest: (state) => {
      state.get_all_menu_type_status.status = FETCH_STATUS_REQUEST
    },
    getAllMenuTypeSuccess: (state, action) => {
      state.get_all_menu_type_status.status = FETCH_STATUS_SUCCESS
      state.menuType = action.payload.menuType
    },
    getAllMenuTypeFailure: (state, action) => {
      state.get_all_menu_type_status.status = FETCH_STATUS_ERROR
      state.get_all_menu_type_status.error = action.payload
    },
    getAllMenuTypeIdle: (state) => {
      state.get_all_menu_type_status.status = FETCH_STATUS_IDLE
    },
    getAllMenuRequest: (state) => {
      state.get_all_menu_status.status = FETCH_STATUS_REQUEST
    },
    getAllMenuSuccess: (state, action) => {
      state.get_all_menu_status.status = FETCH_STATUS_SUCCESS
      state.menu_list = action.payload
    },
    getAllMenuFailure: (state, action) => {
      state.get_all_menu_status.status = FETCH_STATUS_ERROR
      state.get_all_menu_status.error = action.payload
    },
    getAllMenuIdle: (state) => {
      state.get_all_menu_status.status = FETCH_STATUS_IDLE
    },
    getMenuByIdRequest: (state) => {
      state.get_menu_status.status = FETCH_STATUS_REQUEST
    },
    getMenuByIdSuccess: (state, action) => {
      state.get_menu_status.status = FETCH_STATUS_SUCCESS
      state.menuData = action.payload
    },
    getMenuByIdFailure: (state, action) => {
      state.get_menu_status.status = FETCH_STATUS_ERROR
      state.get_menu_status.error = action.payload
    },
    getMenuByIdIdle: (state) => {
      state.get_menu_status.status = FETCH_STATUS_IDLE
    },
    createMenuRequest: (state) => {
      state.create_menu_status.status = FETCH_STATUS_REQUEST
    },
    createMenuSuccess: (state, action) => {
      state.create_menu_status.status = FETCH_STATUS_SUCCESS
      state.menuID = action.payload._id
    },
    createMenuFailure: (state, action) => {
      state.create_menu_status.status = FETCH_STATUS_ERROR
      state.create_menu_status.error = action.payload
    },
    createMenuIdle: (state) => {
      state.create_menu_status.status = FETCH_STATUS_IDLE
    },
    editMenuRequest: (state) => {
      state.edit_menu_status.status = FETCH_STATUS_REQUEST
    },
    editMenuSuccess: (state, action) => {
      state.edit_menu_status.status = FETCH_STATUS_SUCCESS
      state.menuID = action.payload._id
    },
    editMenuFailure: (state, action) => {
      state.edit_menu_status.status = FETCH_STATUS_ERROR
      state.edit_menu_status.error = action.payload
    },
    editMenuIdle: (state) => {
      state.edit_menu_status.status = FETCH_STATUS_IDLE
    },
    deleteMenuRequest: (state) => {
      state.delete_menu_status.status = FETCH_STATUS_REQUEST
    },
    deleteMenuSuccess: (state, action) => {
      state.delete_menu_status.status = FETCH_STATUS_SUCCESS
    },
    deleteMenuFailure: (state, action) => {
      state.delete_menu_status.status = FETCH_STATUS_ERROR
      state.delete_menu_status.error = action.payload
    },
    deleteMenuIdle: (state) => {
      state.delete_menu_status.status = FETCH_STATUS_IDLE
    },
    getSubMenuByIdRequest: (state) => {
      state.get_sub_menu_status.status = FETCH_STATUS_REQUEST
    },
    getSubMenuByIdSuccess: (state, action) => {
      state.get_sub_menu_status.status = FETCH_STATUS_SUCCESS
      state.subMenuData = action.payload
    },
    getSubMenuByIdFailure: (state, action) => {
      state.get_sub_menu_status.status = FETCH_STATUS_ERROR
      state.get_sub_menu_status.error = action.payload
    },
    getSubMenuByIdIdle: (state) => {
      state.get_sub_menu_status.status = FETCH_STATUS_IDLE
    },
    editSubMenuRequest: (state) => {
      state.edit_sub_menu_status.status = FETCH_STATUS_REQUEST
    },
    editSubMenuSuccess: (state, action) => {
      state.edit_sub_menu_status.status = FETCH_STATUS_SUCCESS
    },
    editSubMenuFailure: (state, action) => {
      state.edit_sub_menu_status.status = FETCH_STATUS_ERROR
      state.edit_sub_menu_status.error = action.payload
    },
    editSubMenuIdle: (state) => {
      state.edit_sub_menu_status.status = FETCH_STATUS_IDLE
    },
    createSubMenuRequest: (state) => {
      state.create_sub_menu_status.status = FETCH_STATUS_REQUEST
    },
    createSubMenuSuccess: (state, action) => {
      state.create_sub_menu_status.status = FETCH_STATUS_SUCCESS
    },
    createSubMenuFailure: (state, action) => {
      state.create_sub_menu_status.status = FETCH_STATUS_ERROR
      state.create_sub_menu_status.error = action.payload
    },
    createSubMenuIdle: (state) => {
      state.create_sub_menu_status.status = FETCH_STATUS_IDLE
    },
    deleteSubMenuRequest: (state) => {
      state.delete_sub_menu_status.status = FETCH_STATUS_REQUEST
    },
    deleteSubMenuSuccess: (state, action) => {
      state.delete_sub_menu_status.status = FETCH_STATUS_SUCCESS
    },
    deleteSubMenuFailure: (state, action) => {
      state.delete_sub_menu_status.status = FETCH_STATUS_ERROR
      state.delete_sub_menu_status.error = action.payload
    },
    deleteSubMenuIdle: (state) => {
      state.delete_sub_menu_status.status = FETCH_STATUS_IDLE
    },
    getCustomizeMenuByIdRequest: (state) => {
      state.get_customize_menu_status.status = FETCH_STATUS_REQUEST
    },
    getCustomizeMenuByIdSuccess: (state, action) => {
      state.get_customize_menu_status.status = FETCH_STATUS_SUCCESS
      state.customizeMenuData = action.payload
    },
    getCustomizeMenuByIdFailure: (state, action) => {
      state.get_customize_menu_status.status = FETCH_STATUS_ERROR
      state.get_customize_menu_status.error = action.payload
    },
    getCustomizeMenuByIdIdle: (state) => {
      state.get_customize_menu_status.status = FETCH_STATUS_IDLE
    },
    createCustomizeMenuRequest: (state) => {
      state.create_customize_menu_status.status = FETCH_STATUS_REQUEST
    },
    createCustomizeMenuSuccess: (state, action) => {
      state.create_customize_menu_status.status = FETCH_STATUS_SUCCESS
    },
    createCustomizeMenuFailure: (state, action) => {
      state.create_customize_menu_status.status = FETCH_STATUS_ERROR
      state.create_customize_menu_status.error = action.payload
    },
    createCustomizeMenuIdle: (state) => {
      state.create_customize_menu_status.status = FETCH_STATUS_IDLE
    },
    editCustomizeMenuRequest: (state) => {
      state.edit_customize_menu_status.status = FETCH_STATUS_REQUEST
    },
    editCustomizeMenuSuccess: (state, action) => {
      state.edit_customize_menu_status.status = FETCH_STATUS_SUCCESS
    },
    editCustomizeMenuFailure: (state, action) => {
      state.edit_customize_menu_status.status = FETCH_STATUS_ERROR
      state.edit_customize_menu_status.error = action.payload
    },
    editCustomizeMenuIdle: (state) => {
      state.edit_customize_menu_status.status = FETCH_STATUS_IDLE
    },
    deleteCustomizeMenuRequest: (state) => {
      state.delete_customize_menu_status.status = FETCH_STATUS_REQUEST
    },
    deleteCustomizeMenuSuccess: (state, action) => {
      state.delete_customize_menu_status.status = FETCH_STATUS_SUCCESS
    },
    deleteCustomizeMenuFailure: (state, action) => {
      state.delete_customize_menu_status.status = FETCH_STATUS_ERROR
      state.delete_customize_menu_status.error = action.payload
    },
    deleteCustomizeMenuIdle: (state) => {
      state.delete_customize_menu_status.status = FETCH_STATUS_IDLE
    },
    getChoiceMenuByIdRequest: (state) => {
      state.get_choice_menu_status.status = FETCH_STATUS_REQUEST
    },
    getChoiceMenuByIdSuccess: (state, action) => {
      state.get_choice_menu_status.status = FETCH_STATUS_SUCCESS
      state.choiceMenuData = action.payload
    },
    getChoiceMenuByIdFailure: (state, action) => {
      state.get_choice_menu_status.status = FETCH_STATUS_ERROR
      state.get_choice_menu_status.error = action.payload
    },
    getChoiceMenuByIdIdle: (state) => {
      state.get_choice_menu_status.status = FETCH_STATUS_IDLE
    },
    createChoiceMenuRequest: (state) => {
      state.create_choice_menu_status.status = FETCH_STATUS_REQUEST
    },
    createChoiceMenuSuccess: (state, action) => {
      state.create_choice_menu_status.status = FETCH_STATUS_SUCCESS
    },
    createChoiceMenuFailure: (state, action) => {
      state.create_choice_menu_status.status = FETCH_STATUS_ERROR
      state.create_choice_menu_status.error = action.payload
    },
    createChoiceMenuIdle: (state) => {
      state.create_choice_menu_status.status = FETCH_STATUS_IDLE
    },
    editChoiceMenuRequest: (state) => {
      state.edit_choice_menu_status.status = FETCH_STATUS_REQUEST
    },
    editChoiceMenuSuccess: (state, action) => {
      state.edit_choice_menu_status.status = FETCH_STATUS_SUCCESS
    },
    editChoiceMenuFailure: (state, action) => {
      state.edit_choice_menu_status.status = FETCH_STATUS_ERROR
      state.edit_choice_menu_status.error = action.payload
    },
    editChoiceMenuIdle: (state) => {
      state.edit_choice_menu_status.status = FETCH_STATUS_IDLE
    },
    deleteChoiceMenuRequest: (state) => {
      state.delete_choice_menu_status.status = FETCH_STATUS_REQUEST
    },
    deleteChoiceMenuSuccess: (state, action) => {
      state.delete_choice_menu_status.status = FETCH_STATUS_SUCCESS
    },
    deleteChoiceMenuFailure: (state, action) => {
      state.delete_choice_menu_status.status = FETCH_STATUS_ERROR
      state.delete_choice_menu_status.error = action.payload
    },
    deleteChoiceMenuIdle: (state) => {
      state.delete_choice_menu_status.status = FETCH_STATUS_IDLE
    },
    createChoiceValueRequest: (state) => {
      state.create_choice_value_status.status = FETCH_STATUS_REQUEST
    },
    createChoiceValueSuccess: (state, action) => {
      state.create_choice_value_status.status = FETCH_STATUS_SUCCESS
    },
    createChoiceValueFailure: (state, action) => {
      state.create_choice_value_status.status = FETCH_STATUS_ERROR
      state.create_choice_value_status.error = action.payload
    },
    createChoiceValueIdle: (state) => {
      state.create_choice_value_status.status = FETCH_STATUS_IDLE
    },
    editChoiceValueRequest: (state) => {
      state.edit_choice_value_status.status = FETCH_STATUS_REQUEST
    },
    editChoiceValueSuccess: (state, action) => {
      state.edit_choice_value_status.status = FETCH_STATUS_SUCCESS
    },
    editChoiceValueFailure: (state, action) => {
      state.edit_choice_value_status.status = FETCH_STATUS_ERROR
      state.edit_choice_value_status.error = action.payload
    },
    editChoiceValueIdle: (state) => {
      state.edit_choice_value_status.status = FETCH_STATUS_IDLE
    },
    deleteChoiceValueRequest: (state) => {
      state.delete_choice_value_status.status = FETCH_STATUS_REQUEST
    },
    deleteChoiceValueSuccess: (state, action) => {
      state.delete_choice_value_status.status = FETCH_STATUS_SUCCESS
    },
    deleteChoiceValueFailure: (state, action) => {
      state.delete_choice_value_status.status = FETCH_STATUS_ERROR
      state.delete_choice_value_status.error = action.payload
    },
    deleteChoiceValueIdle: (state) => {
      state.delete_choice_value_status.status = FETCH_STATUS_IDLE
    },
    getSubMenuCustomizeByIdRequest: (state) => {
      state.get_sub_menu_customize_status.status = FETCH_STATUS_REQUEST
    },
    getSubMenuCustomizeByIdSuccess: (state, action) => {
      state.get_sub_menu_customize_status.status = FETCH_STATUS_SUCCESS
      state.subMenuCustomizeData = action.payload
    },
    getSubMenuCustomizeByIdFailure: (state, action) => {
      state.get_sub_menu_customize_status.status = FETCH_STATUS_ERROR
      state.get_sub_menu_customize_status.error = action.payload
    },
    getSubMenuCustomizeByIdIdle: (state) => {
      state.get_sub_menu_customize_status.status = FETCH_STATUS_IDLE
    },
    getSelectedChoiceMenuByIdRequest: (state) => {
      state.get_selected_choice_menu_status.status = FETCH_STATUS_REQUEST
    },
    getSelectedChoiceMenuByIdSuccess: (state, action) => {
      state.get_selected_choice_menu_status.status = FETCH_STATUS_SUCCESS
      state.selectChoiceData = action.payload
    },
    getSelectedChoiceMenuByIdFailure: (state, action) => {
      state.get_selected_choice_menu_status.status = FETCH_STATUS_ERROR
      state.get_selected_choice_menu_status.error = action.payload
    },
    getSelectedChoiceMenuByIdIdle: (state) => {
      state.get_selected_choice_menu_status.status = FETCH_STATUS_IDLE
    },
  },
})

export const menuState = (state) => state.menu

const { actions, reducer } = userSlice

export const {
  getAllMenuTypeRequest,
  getAllMenuTypeSuccess,
  getAllMenuTypeFailure,
  getAllMenuTypeIdle,
  getAllMenuRequest,
  getAllMenuSuccess,
  getAllMenuFailure,
  getAllMenuIdle,
  getMenuByIdRequest,
  getMenuByIdSuccess,
  getMenuByIdFailure,
  getMenuByIdIdle,
  createMenuRequest,
  createMenuSuccess,
  createMenuFailure,
  createMenuIdle,
  editMenuRequest,
  editMenuSuccess,
  editMenuFailure,
  editMenuIdle,
  deleteMenuRequest,
  deleteMenuSuccess,
  deleteMenuFailure,
  deleteMenuIdle,
  getSubMenuByIdRequest,
  getSubMenuByIdSuccess,
  getSubMenuByIdFailure,
  getSubMenuByIdIdle,
  editSubMenuRequest,
  editSubMenuSuccess,
  editSubMenuFailure,
  editSubMenuIdle,
  createSubMenuRequest,
  createSubMenuSuccess,
  createSubMenuFailure,
  createSubMenuIdle,
  deleteSubMenuRequest,
  deleteSubMenuSuccess,
  deleteSubMenuFailure,
  deleteSubMenuIdle,
  getCustomizeMenuByIdRequest,
  getCustomizeMenuByIdSuccess,
  getCustomizeMenuByIdFailure,
  getCustomizeMenuByIdIdle,
  createCustomizeMenuRequest,
  createCustomizeMenuSuccess,
  createCustomizeMenuFailure,
  createCustomizeMenuIdle,
  editCustomizeMenuRequest,
  editCustomizeMenuSuccess,
  editCustomizeMenuFailure,
  editCustomizeMenuIdle,
  deleteCustomizeMenuRequest,
  deleteCustomizeMenuSuccess,
  deleteCustomizeMenuFailure,
  deleteCustomizeMenuIdle,
  getChoiceMenuByIdRequest,
  getChoiceMenuByIdSuccess,
  getChoiceMenuByIdFailure,
  getChoiceMenuByIdIdle,
  createChoiceMenuRequest,
  createChoiceMenuSuccess,
  createChoiceMenuFailure,
  createChoiceMenuIdle,
  editChoiceMenuRequest,
  editChoiceMenuSuccess,
  editChoiceMenuFailure,
  editChoiceMenuIdle,
  deleteChoiceMenuRequest,
  deleteChoiceMenuSuccess,
  deleteChoiceMenuFailure,
  deleteChoiceMenuIdle,
  createChoiceValueRequest,
  createChoiceValueSuccess,
  createChoiceValueFailure,
  createChoiceValueIdle,
  editChoiceValueRequest,
  editChoiceValueSuccess,
  editChoiceValueFailure,
  editChoiceValueIdle,
  deleteChoiceValueRequest,
  deleteChoiceValueSuccess,
  deleteChoiceValueFailure,
  deleteChoiceValueIdle,
  getSubMenuCustomizeByIdRequest,
  getSubMenuCustomizeByIdSuccess,
  getSubMenuCustomizeByIdFailure,
  getSubMenuCustomizeByIdIdle,
  getSelectedChoiceMenuByIdRequest,
  getSelectedChoiceMenuByIdSuccess,
  getSelectedChoiceMenuByIdFailure,
  getSelectedChoiceMenuByIdIdle,
} = actions

export default reducer
