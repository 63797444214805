import { createSlice } from '@reduxjs/toolkit'
import {
  FETCH_STATUS_IDLE, FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_ERROR,
} from '../../../utils/request/status'

const initialState = {
  get_all_order_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  order_list: [],
}

const realtimeOrderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    getAllOrderRequest: (state) => {
      state.get_all_order_status.status = FETCH_STATUS_REQUEST
    },
    getAllOrderSuccess: (state, action) => {
      state.get_all_order_status.status = FETCH_STATUS_SUCCESS
      state.order_list = action.payload
    },
    getAllOrderFailure: (state, action) => {
      state.get_all_order_status.status = FETCH_STATUS_ERROR
      state.get_all_order_status.error = action.payload
    },
    getAllOrderIdle: (state) => {
      state.get_all_order_status.status = FETCH_STATUS_IDLE
    },
  },
})

export const orderState = (state) => state.order

const { actions, reducer } = realtimeOrderSlice

export const {
  getAllOrderRequest,
  getAllOrderSuccess,
  getAllOrderFailure,
  getAllOrderIdle,
} = actions

export default reducer
