import { Offline } from 'react-detect-offline'
import React from 'react'
import {Button, Modal} from "antd"
import {WarningOutlined} from "@ant-design/icons"
import ModalStyled from './styled'

const useCheckInternetConnection = () => {
    const refreshPage = () => {
        window.location.reload()
    }

    const getOfflineModal = () => (
        <Offline>
            <ModalStyled>
                <Modal
                    className="no-internet-connection"
                    title={
                        <div className="modal-title flex align-center">
                            <div className="icon">
                                <WarningOutlined />
                            </div>
                            No internet connection
                        </div>
                    }
                    visible={true}
                    onOk={() => {}}
                    getContainer={false}
                    closable={false}
                    footer={null}
                    icon={<WarningOutlined />}
                >
                    Please check your internet connection
                </Modal>
            </ModalStyled>
        </Offline>
    )

    return {
        getOfflineModal,
    }
}

export default useCheckInternetConnection