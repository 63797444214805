const themeColor = {
  primaryWhite1: '#F5F4F0',
  primaryWhite2: '#EBE8E2',
  primaryWhite3: '#ADABA7',
  primaryGrey1: '#707070',
  primaryGrey2: '#6C6964',
  primaryBrown1: '#211919',
  primaryBrown2: '#191611',
  primaryBrown3: '#121110',
  white: '#FFFFFF',
  test1: '#DE9E48',
  test2: '#7A431D',
  test3: '#563727',
  test4: '#372C2E',
}

export default themeColor
