import { createSlice } from '@reduxjs/toolkit'
import {
  FETCH_STATUS_IDLE, FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_ERROR,
} from '../../../utils/request/status'
import dayjs from 'dayjs'

const calculateAge = (date) => {
  const currentDate = dayjs()
  return currentDate.diff(dayjs(date), 'year')
}

const initialState = {
  overview_list_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  newUserCount: 0,
  salesByPaymentType: undefined,
  salesByOrderType: undefined,
  totalPointUsage: undefined,
  totalDiscountFromPoint: undefined,
  order_list: [],
}

const userSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    getOverviewListRequest: (state) => {
      state.overview_list_status.status = FETCH_STATUS_REQUEST
    },
    getOverviewListSuccess: (state, action) => {
      state.overview_list_status.status = FETCH_STATUS_SUCCESS
      state.newUserCount = action.payload.newUserCount
      state.salesByPaymentType = action.payload.salesByPaymentType
      state.salesByOrderType = action.payload.salesByOrderType
      state.order_list = action.payload.order
      state.totalPointUsage = action.payload.totalPointUsage
      state.totalDiscountFromPoint = action.payload.totalDiscountFromPoint
    },
    getOverviewListFailure: (state, action) => {
      state.overview_list_status.status = FETCH_STATUS_ERROR
      state.overview_list_status.error = action.payload
    },
    getOverviewListIdle: (state) => {
      state.overview_list_status.status = FETCH_STATUS_IDLE
    },
  },
})

export const overviewState = (state) => state.overview

const { actions, reducer } = userSlice

export const {
  getOverviewListRequest,
  getOverviewListSuccess,
  getOverviewListFailure,
  getOverviewListIdle,
} = actions

export default reducer
