export const OVERVIEW_PAGE_PATH = '/'
export const STOCK_PAGE_PATH = '/stock'
export const STOCK_MANAGE_PAGE_PATH = '/inventory/manage'
export const STOCK_LOG_PAGE_PATH = '/inventory/log'
export const MENU_PAGE_PATH = '/menu'
export const MENU_MANAGE_PAGE_PATH = '/menu/manage'
export const SUB_MENU_PAGE_PATH = '/menu/submenu'
export const CUSTOMIZE_SUB_MENU_PAGE_PATH = '/menu/submenu/customize'
export const CHOICE_CUSTOMIZE_SUB_MENU_PAGE_PATH = '/menu/submenu/customize/choice'
export const SALE_PAGE_PATH = '/sales'
export const CUSTOMER_PAGE_PATH = '/customers'
export const PRODUCT_PAGE_PATH = '/products'
export const LOCATION_PICKUP_PAGE_PATH = '/location'
export const LOCATION_PICKUP_MANAGE_PAGE_PATH = '/location/manage'
export const EMPLOYEE_PAGE_PATH = '/employee'
export const EMPLOYEE_MANAGE_PAGE_PATH = '/employee/manage'
export const LOGIN_PAGE_PATH = '/login'
export const BANNER_PAGE_PATH = '/banner'
export const POS_PAGE_PATH = '/pos'
export const POS_CHECKOUT_PAGE_PATH = '/pos/checkout'
export const REALTIME_ORDER_PAGE_PATH = '/realtime'
export const ORDER_HISTORY_PAGE_PATH = '/history'
export const SCHEDULE_PAGE_PATH = '/schedule'
export const PRINTER_PAGE_PATH = '/printer'
export const POINT_PAGE_PATH = '/point'
