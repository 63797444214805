import styled from 'styled-components'
import { devices } from '../../../assets/styles/screenSize'

export default styled.div`
  .no-internet-connection {
    top: 30vh;
    .ant-modal-title {
      display: flex;
      justify-content: center;
      .modal-title {
        font-size: 20px;
        .icon {
          color: red;
          font-size: 30px;
          margin-right: 10px;
        }
      }
    }
    .ant-modal-body {
      display: flex;
      justify-content: center;
    }
  }
`
