import { createSlice } from '@reduxjs/toolkit'
import {
  FETCH_STATUS_IDLE, FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_ERROR,
} from '../../../utils/request/status'
import cloneDeep from 'clone-deep'
import { useMemo } from 'react'

const initialState = {
  get_sub_menu_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  get_user_phone_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  cashier_order_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  subMenuData: undefined,
  cart: [],
  totalPrice: 0,
  totalPriceWithDiscount: 0,
  discountPrice: 0,
  redeemPrice: 0,
  totalQuantity: 0,
  userData: undefined,
}

const userSlice = createSlice({
  name: 'pos',
  initialState,
  reducers: {
    getSubMenuByIdRequest: (state) => {
      state.get_sub_menu_status.status = FETCH_STATUS_REQUEST
      state.subMenuData = undefined
    },
    getSubMenuByIdSuccess: (state, action) => {
      state.get_sub_menu_status.status = FETCH_STATUS_SUCCESS
      state.subMenuData = action.payload
    },
    getSubMenuByIdFailure: (state, action) => {
      state.get_sub_menu_status.status = FETCH_STATUS_ERROR
      state.get_sub_menu_status.error = action.payload
      state.subMenuData = undefined
    },
    getSubMenuByIdIdle: (state) => {
      state.get_sub_menu_status.status = FETCH_STATUS_IDLE
    },
    getUserByPhoneRequest: (state) => {
      state.get_user_phone_status.status = FETCH_STATUS_REQUEST
    },
    getUserByPhoneSuccess: (state, action) => {
      state.get_user_phone_status.status = FETCH_STATUS_SUCCESS
      state.userData = action.payload
      if (action.payload.type !== 'general'){
        state.discountPrice = calculateDiscountPrice(state.totalQuantity)
      } else state.discountPrice = 0
      state.discountPrice = state.discountPrice + action.payload.firstOrderDiscount
      state.totalPriceWithDiscount = calculateTotalPriceWithDiscount(state.totalPrice, state.discountPrice)
    },
    getUserByPhoneFailure: (state, action) => {
      state.get_user_phone_status.status = FETCH_STATUS_ERROR
      state.get_user_phone_status.error = action.payload
      state.discountPrice = 0
      state.totalPriceWithDiscount = state.totalPrice
    },
    getUserByPhoneIdle: (state) => {
      state.get_user_phone_status.status = FETCH_STATUS_IDLE
    },
    cashierOrderRequest: (state) => {
      state.cashier_order_status.status = FETCH_STATUS_REQUEST
    },
    cashierOrderSuccess: (state, action) => {
      state.cashier_order_status.status = FETCH_STATUS_SUCCESS
      state.res = action.payload
    },
    cashierOrderFailure: (state, action) => {
      state.cashier_order_status.status = FETCH_STATUS_ERROR
      state.cashier_order_status.error = action.payload
    },
    cashierOrderIdle: (state) => {
      state.cashier_order_status.status = FETCH_STATUS_IDLE
    },
    updateTotalPrice: (state, action) => {
      state.totalPrice = action.payload
    },
    addCartItem: (state, action) => {
      state.cart = addValidation(state.cart, action.payload)
      state.totalQuantity = calculateTotalItem(state.cart)
      if (state.userData && state.userData?.type !== 'general'){
        state.discountPrice = calculateDiscountPrice(state.totalQuantity)
        state.discountPrice = state.discountPrice + state.redeemPrice
      }
      state.totalPrice = calculateSubTotalPrice(state.cart)
      state.totalPriceWithDiscount = calculateTotalPriceWithDiscount(state.totalPrice, state.discountPrice)
    },
    removeCartItem: (state, action) => {
      state.cart = removeItem(state.cart, action.payload)
      state.totalQuantity = calculateTotalItem(state.cart)
      if (state.userData && state.userData?.type !== 'general'){
        state.discountPrice = calculateDiscountPrice(state.totalQuantity)
        state.discountPrice = state.discountPrice + state.redeemPrice
      }
      state.totalPrice = calculateSubTotalPrice(state.cart)
      state.totalPriceWithDiscount = calculateTotalPriceWithDiscount(state.totalPrice, state.discountPrice)
    },
    clearCartItem: (state, action) => {
      state.cart = []
      state.userData = undefined
      state.totalQuantity = 0
      state.totalPrice = 0
      state.totalPriceWithDiscount = 0
      state.discountPrice = 0
      state.redeemPrice = 0
    },
    clearRedeemPoint: (state, action) => {
      state.redeemPrice = 0
    },
    increaseCartItemQuantity: (state, action) => {
      state.cart = increaseQuantity(state.cart, action.payload)
      state.totalQuantity = calculateTotalItem(state.cart)
      state.totalPrice = calculateSubTotalPrice(state.cart)
      if (state.userData && state.userData?.type !== 'general'){
        state.discountPrice = calculateDiscountPrice(state.totalQuantity)
        state.discountPrice = state.discountPrice + state.redeemPrice
      }
      state.totalPriceWithDiscount = calculateTotalPriceWithDiscount(state.totalPrice, state.discountPrice)
    },
    decreaseCartItemQuantity: (state, action) => {
      state.cart = decreaseQuantity(state.cart, action.payload)
      state.totalQuantity = calculateTotalItem(state.cart)
      state.totalPrice = calculateSubTotalPrice(state.cart)
      if (state.userData?.type && state.userData?.type !== 'general'){
        state.discountPrice = calculateDiscountPrice(state.totalQuantity)
        state.discountPrice = state.discountPrice + state.redeemPrice
      }
      state.totalPriceWithDiscount = calculateTotalPriceWithDiscount(state.totalPrice, state.discountPrice)
    },
    increaseDiscountByRedeemPoint: (state, action) => {
      state.redeemPrice = action.payload
      state.discountPrice = state.discountPrice + action.payload
      state.totalPriceWithDiscount = calculateTotalPriceWithDiscount(state.totalPrice, state.discountPrice)
    },
    decreaseDiscountByCancelRedeemPoint: (state, action) => {
      state.redeemPrice = action.payload
      state.discountPrice = state.discountPrice - action.payload
      state.totalPriceWithDiscount = calculateTotalPriceWithDiscount(state.totalPrice, state.discountPrice)
    }
  },
})

const checkPositiveValue = (value) => {
  return value > 0 ? value : 0
}

const calculateTotalItem = (itemList) => {
  let quantity = 0
  itemList.map(item => {
    quantity += item.quantity
  })
  return quantity
}

const calculateTotalPriceWithDiscount = (totalPrice, discountPrice) => {
  const priceWithDiscount = parseFloat(totalPrice) - parseFloat(discountPrice)
  return parseFloat(priceWithDiscount.toFixed(2))
}

const calculateDiscountPrice = (totalQuantity) => {
  return parseFloat((totalQuantity*5).toFixed(2))
}

const calculateSubTotalPrice = (itemList) => {
  let priceList = []
  let totalPrice = 0
  itemList.map((menu, index) => {
    priceList[index] = 0
    priceList[index] += menu.subMenu.basePrice
    menu.subMenu.customizes.map(customize => {
      customize.choice.map(choice => {
        priceList[index] += choice.price
      })
    })
    priceList[index] *= menu.quantity
  })
  priceList.map(price => {
    totalPrice += price
  })
  return parseFloat(totalPrice.toFixed(2))
}

const isObjEqual = (obj1, obj2) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2)
}

const addValidation = (oldCart, addedMenu) => {
  const tempArray = cloneDeep(oldCart)
  let decision = []
  let arrIndex = []
  if (tempArray.length > 0) {
    tempArray.map((menu, index) => {
      if(menu._id === addedMenu._id && isObjEqual(menu.subMenu, addedMenu.subMenu)) {
        decision.push('oldAdd')
        arrIndex.push(index)
      } else {
        decision.push('newAdd')
      }
    })
  } else {
    decision.push('newAdd')
  }
  if (decision.includes('oldAdd')) {
    tempArray[arrIndex[0]] = {
      ...addedMenu,
      quantity: tempArray[arrIndex[0]].quantity+1,
    }
  } else {
    tempArray.push({
      ...addedMenu,
      quantity: 1
    })
  }
  return tempArray
}

const removeItem = (oldCart, addedMenu) => {
  const tempArray = cloneDeep(oldCart)
  tempArray.map((menu, index) => {
    if(menu._id === addedMenu._id && isObjEqual(menu.subMenu, addedMenu.subMenu)) {
      tempArray.splice(index, 1)
    }
  })
  return tempArray
}

const increaseQuantity = (oldCart, addedMenu) => {
  const tempArray = cloneDeep(oldCart)
  tempArray.map((menu, index) => {
    if(menu._id === addedMenu._id && isObjEqual(menu.subMenu, addedMenu.subMenu)) {
      tempArray[index] = {
        ...addedMenu,
        quantity: menu.quantity+1,
      }
    }
  })
  return tempArray
}

const decreaseQuantity = (oldCart, addedMenu) => {
  const tempArray = cloneDeep(oldCart)
  tempArray.map((menu, index) => {
    if(menu._id === addedMenu._id && isObjEqual(menu.subMenu, addedMenu.subMenu)) {
      tempArray[index] = {
        ...addedMenu,
        quantity: menu.quantity > 0 ? menu.quantity-1 : 0,
      }
    }
  })
  return tempArray
}

export const posState = (state) => state.pos

const { actions, reducer } = userSlice

export const {
  getSubMenuByIdRequest,
  getSubMenuByIdSuccess,
  getSubMenuByIdFailure,
  getSubMenuByIdIdle,
  getUserByPhoneRequest,
  getUserByPhoneSuccess,
  getUserByPhoneFailure,
  getUserByPhoneIdle,
  cashierOrderRequest,
  cashierOrderSuccess,
  cashierOrderFailure,
  cashierOrderIdle,
  updateTotalPrice,
  addCartItem,
  removeCartItem,
  clearCartItem,
  increaseCartItemQuantity,
  decreaseCartItemQuantity,
  increaseDiscountByRedeemPoint,
  decreaseDiscountByCancelRedeemPoint,
  clearRedeemPoint,
} = actions

export default reducer
