import React, { useMemo } from 'react'
import { Route, Switch } from 'react-router-dom'

import {
  LOGIN_PAGE_PATH,
  OVERVIEW_PAGE_PATH,
  STOCK_PAGE_PATH,
  STOCK_MANAGE_PAGE_PATH,
  STOCK_LOG_PAGE_PATH,
  EMPLOYEE_PAGE_PATH,
  EMPLOYEE_MANAGE_PAGE_PATH,
  MENU_PAGE_PATH,
  MENU_MANAGE_PAGE_PATH,
  SUB_MENU_PAGE_PATH,
  CUSTOMIZE_SUB_MENU_PAGE_PATH,
  CHOICE_CUSTOMIZE_SUB_MENU_PAGE_PATH,
  LOCATION_PICKUP_PAGE_PATH,
  LOCATION_PICKUP_MANAGE_PAGE_PATH,
  CUSTOMER_PAGE_PATH,
  BANNER_PAGE_PATH,
  POS_PAGE_PATH,
  POS_CHECKOUT_PAGE_PATH,
  REALTIME_ORDER_PAGE_PATH,
  SCHEDULE_PAGE_PATH,
  PRINTER_PAGE_PATH, ORDER_HISTORY_PAGE_PATH, POINT_PAGE_PATH,
} from './routes'

import LoginPage from '../containers/pages/Login/loadable'
import ApplicationLayout from '../containers/ApplicationLayout/loadable'
import OverviewPage from '../containers/pages/Overview/loadable'
import MenuList from '../containers/pages/MenuList/loadable'
import MenuManagement from '../containers/pages/MenuManagement/loadable'
import SubMenuManagement from '../containers/pages/SubMenuManagement/loadable'
import CustomizeMenuManagement from '../containers/pages/CustomizeMenuManagement/loadable'
import ChoiceMenuManagement from '../containers/pages/ChoiceMenuManagement/loadable'
import EmployeeList from '../containers/pages/EmployeeList/loadable'
import EmployeeManagement from '../containers/pages/EmployeeManagement/loadable'
import StockList from '../containers/pages/StockList/loadable'
import StockManagement from '../containers/pages/StockManagement/loadable'
import StockLog from '../containers/pages/StockLog/loadable'
import LocationList from '../containers/pages/LocationList/loadable'
import LocationManagement from '../containers/pages/LocationManagement/loadable'
import CustomerList from '../containers/pages/CustomerList/loadable'
import BannerList from '../containers/pages/BannerList/loadable'
import PosPage from '../containers/pages/Pos/loadable'
import PosCheckoutPage from '../containers/pages/PosCheckout/loadable'
import RealTimeOrderList from '../containers/pages/RealTimeOrderList/loadable'
import OrderHistoryList from '../containers/pages/OrderHistoryList/loadable'
import SchedulePage from '../containers/pages/Schedule/loadable'
import PrinterPage from '../containers/pages/Printer/loadable'
import PointPage from '../containers/pages/PointManagement/loadable'
import useCheckInternetConnection from "../utils/hook/useCheckInternetConnection"

const RouterPage = () => {
  const { getOfflineModal } = useCheckInternetConnection()

  const getAllPage = useMemo(() => (
      <Switch>
        <Route path={LOGIN_PAGE_PATH} component={LoginPage} />
        <ApplicationLayout>
          <Route exact path={OVERVIEW_PAGE_PATH} component={OverviewPage} />
          <Route exact path={MENU_PAGE_PATH} component={MenuList} />
          <Route exact path={MENU_MANAGE_PAGE_PATH} component={MenuManagement} />
          <Route exact path={SUB_MENU_PAGE_PATH} component={SubMenuManagement} />
          <Route exact path={CUSTOMIZE_SUB_MENU_PAGE_PATH} component={CustomizeMenuManagement} />
          <Route path={CHOICE_CUSTOMIZE_SUB_MENU_PAGE_PATH} component={ChoiceMenuManagement} />
          <Route exact path={STOCK_PAGE_PATH} component={StockList} />
          <Route path={STOCK_MANAGE_PAGE_PATH} component={StockManagement} />
          <Route path={STOCK_LOG_PAGE_PATH} component={StockLog} />
          <Route exact path={EMPLOYEE_PAGE_PATH} component={EmployeeList} />
          <Route path={EMPLOYEE_MANAGE_PAGE_PATH} component={EmployeeManagement} />
          <Route exact path={LOCATION_PICKUP_PAGE_PATH} component={LocationList} />
          <Route path={LOCATION_PICKUP_MANAGE_PAGE_PATH} component={LocationManagement} />
          <Route path={CUSTOMER_PAGE_PATH} component={CustomerList} />
          <Route path={BANNER_PAGE_PATH} component={BannerList} />
          <Route exact path={POS_PAGE_PATH} component={PosPage} />
          <Route path={POS_CHECKOUT_PAGE_PATH} component={PosCheckoutPage} />
          <Route path={REALTIME_ORDER_PAGE_PATH} component={RealTimeOrderList} />
          <Route path={ORDER_HISTORY_PAGE_PATH} component={OrderHistoryList} />
          <Route path={SCHEDULE_PAGE_PATH} component={SchedulePage} />
          <Route path={PRINTER_PAGE_PATH} component={PrinterPage} />
          <Route path={POINT_PAGE_PATH} component={PointPage} />
        </ApplicationLayout>
      </Switch>
  ), [])

  return (
    <div className="App" id="App">
      {getAllPage}
      {getOfflineModal()}
    </div>
  )
}

export default RouterPage
