import { createSlice } from '@reduxjs/toolkit'
import {
  FETCH_STATUS_IDLE, FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_ERROR,
} from '../../../utils/request/status'

const initialState = {
  get_all_realtime_order_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  set_done_order_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  set_refund_order_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  order_list: [],
}

const realtimeOrderSlice = createSlice({
  name: 'realtimeOrder',
  initialState,
  reducers: {
    getAllRealtimeOrderRequest: (state) => {
      state.get_all_realtime_order_status.status = FETCH_STATUS_REQUEST
    },
    getAllRealtimeOrderSuccess: (state, action) => {
      state.get_all_realtime_order_status.status = FETCH_STATUS_SUCCESS
      state.order_list = action.payload
    },
    getAllRealtimeOrderFailure: (state, action) => {
      state.get_all_realtime_order_status.status = FETCH_STATUS_ERROR
      state.get_all_realtime_order_status.error = action.payload
    },
    getAllRealtimeOrderIdle: (state) => {
      state.get_all_realtime_order_status.status = FETCH_STATUS_IDLE
    },
    setDoneRealTimeOrderRequest: (state) => {
      state.set_done_order_status.status = FETCH_STATUS_REQUEST
    },
    setDoneRealTimeOrderSuccess: (state, action) => {
      state.set_done_order_status.status = FETCH_STATUS_SUCCESS
    },
    setDoneRealTimeOrderFailure: (state, action) => {
      state.set_done_order_status.status = FETCH_STATUS_ERROR
      state.set_done_order_status.error = action.payload
    },
    setDoneRealTimeOrderIdle: (state) => {
      state.set_done_order_status.status = FETCH_STATUS_IDLE
    },
    setRefundRealTimeOrderRequest: (state) => {
      state.set_refund_order_status.status = FETCH_STATUS_REQUEST
    },
    setRefundRealTimeOrderSuccess: (state, action) => {
      state.set_refund_order_status.status = FETCH_STATUS_SUCCESS
    },
    setRefundRealTimeOrderFailure: (state, action) => {
      state.set_refund_order_status.status = FETCH_STATUS_ERROR
      state.set_refund_order_status.error = action.payload
    },
    setRefundRealTimeOrderIdle: (state) => {
      state.set_refund_order_status.status = FETCH_STATUS_IDLE
    },
  },
})

export const realtimeOrderState = (state) => state.realtimeOrder

const { actions, reducer } = realtimeOrderSlice

export const {
  getAllRealtimeOrderRequest,
  getAllRealtimeOrderSuccess,
  getAllRealtimeOrderFailure,
  getAllRealtimeOrderIdle,
  setDoneRealTimeOrderRequest,
  setDoneRealTimeOrderSuccess,
  setDoneRealTimeOrderFailure,
  setDoneRealTimeOrderIdle,
  setRefundRealTimeOrderRequest,
  setRefundRealTimeOrderSuccess,
  setRefundRealTimeOrderFailure,
  setRefundRealTimeOrderIdle,
} = actions

export default reducer
