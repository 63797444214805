import { createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import {
  FETCH_STATUS_IDLE, FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_ERROR,
} from '../../../utils/request/status'

const calculateAge = (date) => {
  const currentDate = dayjs()
  return currentDate.diff(dayjs(date), 'year')
}

const initialState = {
  get_all_stock_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  create_stock_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  edit_stock_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  delete_stock_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  fill_stock_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  track_stock_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  stock_list: [],
  stock_log: [],
}

const userSlice = createSlice({
  name: 'stock',
  initialState,
  reducers: {
    getAllStockRequest: (state) => {
      state.get_all_stock_status.status = FETCH_STATUS_REQUEST
    },
    getAllStockSuccess: (state, action) => {
      state.get_all_stock_status.status = FETCH_STATUS_SUCCESS
      state.stock_list = action.payload
    },
    getAllStockFailure: (state, action) => {
      state.get_all_stock_status.status = FETCH_STATUS_ERROR
      state.get_all_stock_status.error = action.payload
    },
    getAllStockIdle: (state) => {
      state.get_all_stock_status.status = FETCH_STATUS_IDLE
    },
    createStockRequest: (state) => {
      state.create_stock_status.status = FETCH_STATUS_REQUEST
    },
    createStockSuccess: (state) => {
      state.create_stock_status.status = FETCH_STATUS_SUCCESS
    },
    createStockFailure: (state, action) => {
      state.create_stock_status.status = FETCH_STATUS_ERROR
      state.create_stock_status.error = action.payload
    },
    createStockIdle: (state) => {
      state.create_stock_status.status = FETCH_STATUS_IDLE
    },
    editStockRequest: (state) => {
      state.edit_stock_status.status = FETCH_STATUS_REQUEST
    },
    editStockSuccess: (state) => {
      state.edit_stock_status.status = FETCH_STATUS_SUCCESS
    },
    editStockFailure: (state, action) => {
      state.edit_stock_status.status = FETCH_STATUS_ERROR
      state.edit_stock_status.error = action.payload
    },
    editStockIdle: (state) => {
      state.edit_stock_status.status = FETCH_STATUS_IDLE
    },
    deleteStockRequest: (state) => {
      state.delete_stock_status.status = FETCH_STATUS_REQUEST
    },
    deleteStockSuccess: (state) => {
      state.delete_stock_status.status = FETCH_STATUS_SUCCESS
    },
    deleteStockFailure: (state, action) => {
      state.delete_stock_status.status = FETCH_STATUS_ERROR
      state.delete_stock_status.error = action.payload
    },
    deleteStockIdle: (state) => {
      state.delete_stock_status.status = FETCH_STATUS_IDLE
    },
    fillStockRequest: (state) => {
      state.fill_stock_status.status = FETCH_STATUS_REQUEST
    },
    fillStockSuccess: (state) => {
      state.fill_stock_status.status = FETCH_STATUS_SUCCESS
    },
    fillStockFailure: (state, action) => {
      state.fill_stock_status.status = FETCH_STATUS_ERROR
      state.fill_stock_status.error = action.payload
    },
    fillStockIdle: (state) => {
      state.fill_stock_status.status = FETCH_STATUS_IDLE
    },
    getTrackStockRequest: (state) => {
      state.track_stock_status.status = FETCH_STATUS_REQUEST
    },
    getTrackStockSuccess: (state, action) => {
      state.track_stock_status.status = FETCH_STATUS_SUCCESS
      state.stock_log = action.payload
    },
    getTrackStockFailure: (state, action) => {
      state.track_stock_status.status = FETCH_STATUS_ERROR
      state.track_stock_status.error = action.payload
    },
    getTrackStockIdle: (state) => {
      state.track_stock_status.status = FETCH_STATUS_IDLE
    },
  },
})

export const stockState = (state) => state.stock

const { actions, reducer } = userSlice

export const {
  getAllStockRequest,
  getAllStockSuccess,
  getAllStockFailure,
  getAllStockIdle,
  createStockRequest,
  createStockSuccess,
  createStockFailure,
  createStockIdle,
  editStockRequest,
  editStockSuccess,
  editStockFailure,
  editStockIdle,
  deleteStockRequest,
  deleteStockSuccess,
  deleteStockFailure,
  deleteStockIdle,
  fillStockRequest,
  fillStockSuccess,
  fillStockFailure,
  fillStockIdle,
  getTrackStockRequest,
  getTrackStockSuccess,
  getTrackStockFailure,
  getTrackStockIdle,
} = actions

export default reducer
