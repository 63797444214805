import { createSlice } from '@reduxjs/toolkit'
import { storage } from '../../../utils/token'

import {
  FETCH_STATUS_IDLE, FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_ERROR,
} from '../../../utils/request/status'

const initialState = {
  login_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  verify_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  resend_otp_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  forget_password_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  set_password_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  load_user_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  isTokenExpire: false,
  verificationNeeded: false,
  resetPassNeeded: false,
  isLogin: !!storage.get('jwtToken'),
  user: undefined,
  phone: undefined,
  preToken: undefined,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setTokenExpire: (state, action) => {
      state.isTokenExpire = action.payload
    },
    loginRequest: (state) => {
      state.login_status.status = FETCH_STATUS_REQUEST
    },
    loginSuccess: (state, action) => {
      state.login_status.status = FETCH_STATUS_SUCCESS
      state.verificationNeeded = action.payload.expired
      state.phone = action.payload.phone
      state.isTokenExpire = false
      state.login_status.error = undefined
      if (action.payload.expired) state.preToken = action.payload.token
      if (!action.payload.expired) state.isLogin = true
    },
    loginFailure: (state, action) => {
      state.login_status.status = FETCH_STATUS_ERROR
      state.login_status.error = action.payload
    },
    loginIdle: (state) => {
      state.login_status.status = FETCH_STATUS_IDLE
    },
    verifyOTPRequest: (state) => {
      state.verify_status.status = FETCH_STATUS_REQUEST
    },
    verifyOTPSuccess: (state, action) => {
      state.verify_status.status = FETCH_STATUS_SUCCESS
      state.verify_status.error = undefined
      state.verificationNeeded = false
      state.isTokenExpire = false
      if (action.payload.type === 'login') state.isLogin = true
      if (action.payload.type === 'forgotPassword') {
        state.resetPassNeeded = true
        state.preToken = action.payload.token
      }
    },
    verifyOTPFailure: (state, action) => {
      state.verify_status.status = FETCH_STATUS_ERROR
      state.verify_status.error = action.payload
      state.islogin = false
      state.verificationNeeded = true
    },
    verifyOTPIdle: (state) => {
      state.verify_status.status = FETCH_STATUS_IDLE
    },
    resendOTPRequest: (state) => {
      state.resend_otp_status.status = FETCH_STATUS_REQUEST
    },
    resendOTPSuccess: (state) => {
      state.resend_otp_status.status = FETCH_STATUS_SUCCESS
      state.resend_otp_status.error = undefined
    },
    resendOTPFailure: (state, action) => {
      state.resend_otp_status.status = FETCH_STATUS_ERROR
      state.resend_otp_status.error = action.payload
    },
    resendOTPIdle: (state) => {
      state.resend_otp_status.status = FETCH_STATUS_IDLE
      state.resend_otp_status.error = undefined
    },
    logoutSuccess: (state) => {
      state.isLogin = false
      state.isTokenExpire = false
    },
    forgotPasswordRequest: (state) => {
      state.forget_password_status.status = FETCH_STATUS_REQUEST
    },
    forgotPasswordSuccess: (state, action) => {
      state.forget_password_status.status = FETCH_STATUS_SUCCESS
      state.forget_password_status.error = undefined
      state.preToken = action.payload.token
      state.verificationNeeded = true
    },
    forgotPasswordFailure: (state, action) => {
      state.forget_password_status.status = FETCH_STATUS_ERROR
      state.forget_password_status.error = action.payload
    },
    forgotPasswordIdle: (state) => {
      state.forget_password_status.status = FETCH_STATUS_IDLE
    },
    setPasswordRequest: (state) => {
      state.set_password_status.status = FETCH_STATUS_REQUEST
    },
    setPasswordSuccess: (state, action) => {
      state.set_password_status.status = FETCH_STATUS_SUCCESS
      state.set_password_status.error = undefined
      state.resetPassNeeded = false
    },
    setPasswordFailure: (state, action) => {
      state.set_password_status.status = FETCH_STATUS_ERROR
      state.set_password_status.error = action.payload
      state.resetPassNeeded = true
    },
    setPasswordIdle: (state) => {
      state.set_password_status.status = FETCH_STATUS_IDLE
    },
    getCurrentUserDataRequest: (state) => {
      state.load_user_status.status = FETCH_STATUS_REQUEST
    },
    getCurrentUserDataSuccess: (state, action) => {
      state.load_user_status.status = FETCH_STATUS_SUCCESS
      state.load_user_status.error = undefined
      state.user = action.payload
    },
    getCurrentUserDataFailure: (state, action) => {
      state.load_user_status.status = FETCH_STATUS_ERROR
      state.load_user_status.error = action.payload
      state.resetPassNeeded = true
    },
    getCurrentUserDataIdle: (state) => {
      state.load_user_status.status = FETCH_STATUS_IDLE
    },
  },
})

export const userState = (state) => state.user

const { actions, reducer } = userSlice

export const {
  setTokenExpire,
  loginRequest,
  loginSuccess,
  loginFailure,
  loginIdle,
  verifyOTPRequest,
  verifyOTPSuccess,
  verifyOTPFailure,
  verifyOTPIdle,
  resendOTPRequest,
  resendOTPSuccess,
  resendOTPFailure,
  resendOTPIdle,
  forgotPasswordRequest,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  forgotPasswordIdle,
  setPasswordRequest,
  setPasswordSuccess,
  setPasswordFailure,
  setPasswordIdle,
  logoutSuccess,
  getCurrentUserDataRequest,
  getCurrentUserDataSuccess,
  getCurrentUserDataFailure,
  getCurrentUserDataIdle,
} = actions

export default reducer
