import { createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import {
  FETCH_STATUS_IDLE, FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_ERROR,
} from '../../../utils/request/status'

const calculateAge = (date) => {
  const currentDate = dayjs()
  return currentDate.diff(dayjs(date), 'year')
}

const initialState = {
  get_all_employee_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  create_employee_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  edit_employee_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  delete_employee_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  get_employee_phone_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  edit_employee_phone_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  employee_list: [],
  employeePhone: undefined,
}

const userSlice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    getAllEmployeeRequest: (state) => {
      state.get_all_employee_status.status = FETCH_STATUS_REQUEST
    },
    getAllEmployeeSuccess: (state, action) => {
      state.get_all_employee_status.status = FETCH_STATUS_SUCCESS
      state.employee_list = action.payload.map( employee => (
        {
          ...employee,
          age: calculateAge(employee.dob),
          name: `${employee.firstname} ${employee.lastname}` 
        }
      ))
    },
    getAllEmployeeFailure: (state, action) => {
      state.get_all_employee_status.status = FETCH_STATUS_ERROR
      state.get_all_employee_status.error = action.payload
    },
    getAllEmployeeIdle: (state) => {
      state.get_all_employee_status.status = FETCH_STATUS_IDLE
    },
    createEmployeeRequest: (state) => {
      state.create_employee_status.status = FETCH_STATUS_REQUEST
    },
    createEmployeeSuccess: (state) => {
      state.create_employee_status.status = FETCH_STATUS_SUCCESS
    },
    createEmployeeFailure: (state, action) => {
      state.create_employee_status.status = FETCH_STATUS_ERROR
      state.create_employee_status.error = action.payload
    },
    createEmployeeIdle: (state) => {
      state.create_employee_status.status = FETCH_STATUS_IDLE
    },
    editEmployeeRequest: (state) => {
      state.edit_employee_status.status = FETCH_STATUS_REQUEST
    },
    editEmployeeSuccess: (state) => {
      state.edit_employee_status.status = FETCH_STATUS_SUCCESS
    },
    editEmployeeFailure: (state, action) => {
      state.edit_employee_status.status = FETCH_STATUS_ERROR
      state.edit_employee_status.error = action.payload
    },
    editEmployeeIdle: (state) => {
      state.edit_employee_status.status = FETCH_STATUS_IDLE
    },
    deleteEmployeeRequest: (state) => {
      state.delete_employee_status.status = FETCH_STATUS_REQUEST
    },
    deleteEmployeeSuccess: (state) => {
      state.delete_employee_status.status = FETCH_STATUS_SUCCESS
    },
    deleteEmployeeFailure: (state, action) => {
      state.delete_employee_status.status = FETCH_STATUS_ERROR
      state.delete_employee_status.error = action.payload
    },
    deleteEmployeeIdle: (state) => {
      state.delete_employee_status.status = FETCH_STATUS_IDLE
    },
    getEmployeePhoneRequest: (state) => {
      state.get_employee_phone_status.status = FETCH_STATUS_REQUEST
    },
    getEmployeePhoneSuccess: (state, action) => {
      state.get_employee_phone_status.status = FETCH_STATUS_SUCCESS
      state.employeePhone = action.payload
    },
    getEmployeePhoneFailure: (state, action) => {
      state.get_employee_phone_status.status = FETCH_STATUS_ERROR
      state.get_employee_phone_status.error = action.payload
    },
    getEmployeePhoneIdle: (state) => {
      state.get_employee_phone_status.status = FETCH_STATUS_IDLE
    },
    editEmployeePhoneRequest: (state) => {
      state.edit_employee_phone_status.status = FETCH_STATUS_REQUEST
    },
    editEmployeePhoneSuccess: (state, action) => {
      state.edit_employee_phone_status.status = FETCH_STATUS_SUCCESS
      state.employeePhone = action.payload
    },
    editEmployeePhoneFailure: (state, action) => {
      state.edit_employee_phone_status.status = FETCH_STATUS_ERROR
      state.edit_employee_phone_status.error = action.payload
    },
    editEmployeePhoneIdle: (state) => {
      state.edit_employee_phone_status.status = FETCH_STATUS_IDLE
    },
  },
})

export const employeeState = (state) => state.employee

const { actions, reducer } = userSlice

export const {
  getAllEmployeeRequest,
  getAllEmployeeSuccess,
  getAllEmployeeFailure,
  getAllEmployeeIdle,
  createEmployeeRequest,
  createEmployeeSuccess,
  createEmployeeFailure,
  createEmployeeIdle,
  editEmployeeRequest,
  editEmployeeSuccess,
  editEmployeeFailure,
  editEmployeeIdle,
  deleteEmployeeRequest,
  deleteEmployeeSuccess,
  deleteEmployeeFailure,
  deleteEmployeeIdle,
  getEmployeePhoneRequest,
  getEmployeePhoneSuccess,
  getEmployeePhoneFailure,
  getEmployeePhoneIdle,
  editEmployeePhoneRequest,
  editEmployeePhoneSuccess,
  editEmployeePhoneFailure,
  editEmployeePhoneIdle,
} = actions

export default reducer
