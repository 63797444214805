import { createSlice } from '@reduxjs/toolkit'
import {
  FETCH_STATUS_IDLE, FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_ERROR,
} from '../../../utils/request/status'

const initialState = {
  get_all_location_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  create_location_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  edit_location_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  delete_location_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  location_list: [],
}

const userSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    getAllLocationRequest: (state) => {
      state.get_all_location_status.status = FETCH_STATUS_REQUEST
    },
    getAllLocationSuccess: (state, action) => {
      state.get_all_location_status.status = FETCH_STATUS_SUCCESS
      state.location_list = action.payload
    },
    getAllLocationFailure: (state, action) => {
      state.get_all_location_status.status = FETCH_STATUS_ERROR
      state.get_all_location_status.error = action.payload
    },
    getAllLocationIdle: (state) => {
      state.get_all_location_status.status = FETCH_STATUS_IDLE
    },
    createLocationRequest: (state) => {
      state.create_location_status.status = FETCH_STATUS_REQUEST
    },
    createLocationSuccess: (state) => {
      state.create_location_status.status = FETCH_STATUS_SUCCESS
    },
    createLocationFailure: (state, action) => {
      state.create_location_status.status = FETCH_STATUS_ERROR
      state.create_location_status.error = action.payload
    },
    createLocationIdle: (state) => {
      state.create_location_status.status = FETCH_STATUS_IDLE
    },
    editLocationRequest: (state) => {
      state.edit_location_status.status = FETCH_STATUS_REQUEST
    },
    editLocationSuccess: (state) => {
      state.edit_location_status.status = FETCH_STATUS_SUCCESS
    },
    editLocationFailure: (state, action) => {
      state.edit_location_status.status = FETCH_STATUS_ERROR
      state.edit_location_status.error = action.payload
    },
    editLocationIdle: (state) => {
      state.edit_location_status.status = FETCH_STATUS_IDLE
    },
    deleteLocationRequest: (state) => {
      state.delete_location_status.status = FETCH_STATUS_REQUEST
    },
    deleteLocationSuccess: (state) => {
      state.delete_location_status.status = FETCH_STATUS_SUCCESS
    },
    deleteLocationFailure: (state, action) => {
      state.delete_location_status.status = FETCH_STATUS_ERROR
      state.delete_location_status.error = action.payload
    },
    deleteLocationIdle: (state) => {
      state.delete_location_status.status = FETCH_STATUS_IDLE
    },
  },
})

export const locationState = (state) => state.location

const { actions, reducer } = userSlice

export const {
  getAllLocationRequest,
  getAllLocationSuccess,
  getAllLocationFailure,
  getAllLocationIdle,
  createLocationRequest,
  createLocationSuccess,
  createLocationFailure,
  createLocationIdle,
  editLocationRequest,
  editLocationSuccess,
  editLocationFailure,
  editLocationIdle,
  deleteLocationRequest,
  deleteLocationSuccess,
  deleteLocationFailure,
  deleteLocationIdle,
} = actions

export default reducer
