import { createSlice } from '@reduxjs/toolkit'
import {
  FETCH_STATUS_IDLE, FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_ERROR,
} from '../../../utils/request/status'

const initialState = {
  get_all_schedule_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  get_schedule_by_id_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  create_schedule_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  add_delivery_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  edit_schedule_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  delete_schedule_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  delete_delivery_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  close_schedule_time_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  schedule_list: [],
  deliveryData: undefined,
}

const userSlice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    getAllScheduleRequest: (state) => {
      state.get_all_schedule_status.status = FETCH_STATUS_REQUEST
    },
    getAllScheduleSuccess: (state, action) => {
      state.get_all_schedule_status.status = FETCH_STATUS_SUCCESS
      state.schedule_list = action.payload
    },
    getAllScheduleFailure: (state, action) => {
      state.get_all_schedule_status.status = FETCH_STATUS_ERROR
      state.get_all_schedule_status.error = action.payload
    },
    getAllScheduleIdle: (state) => {
      state.get_all_schedule_status.status = FETCH_STATUS_IDLE
    },
    getScheduleRequest: (state) => {
      state.get_schedule_by_id_status.status = FETCH_STATUS_REQUEST
    },
    getScheduleSuccess: (state, action) => {
      state.get_schedule_by_id_status.status = FETCH_STATUS_SUCCESS
      state.deliveryData = action.payload
    },
    getScheduleFailure: (state, action) => {
      state.get_schedule_by_id_status.status = FETCH_STATUS_ERROR
      state.get_schedule_by_id_status.error = action.payload
    },
    getScheduleIdle: (state) => {
      state.get_schedule_by_id_status.status = FETCH_STATUS_IDLE
    },
    createScheduleRequest: (state) => {
      state.create_schedule_status.status = FETCH_STATUS_REQUEST
    },
    createScheduleSuccess: (state, action) => {
      state.create_schedule_status.status = FETCH_STATUS_SUCCESS
      state.deliveryData = action.payload[0]
    },
    createScheduleFailure: (state, action) => {
      state.create_schedule_status.status = FETCH_STATUS_ERROR
      state.create_schedule_status.error = action.payload
    },
    createScheduleIdle: (state) => {
      state.create_schedule_status.status = FETCH_STATUS_IDLE
    },
    addDeliveryRequest: (state) => {
      state.add_delivery_status.status = FETCH_STATUS_REQUEST
    },
    addDeliverySuccess: (state) => {
      state.add_delivery_status.status = FETCH_STATUS_SUCCESS
    },
    addDeliveryFailure: (state, action) => {
      state.add_delivery_status.status = FETCH_STATUS_ERROR
      state.add_delivery_status.error = action.payload
    },
    addDeliveryIdle: (state) => {
      state.add_delivery_status.status = FETCH_STATUS_IDLE
    },
    deleteDeliveryRequest: (state) => {
      state.delete_delivery_status.status = FETCH_STATUS_REQUEST
    },
    deleteDeliverySuccess: (state) => {
      state.delete_delivery_status.status = FETCH_STATUS_SUCCESS
    },
    deleteDeliveryFailure: (state, action) => {
      state.delete_delivery_status.status = FETCH_STATUS_ERROR
      state.delete_delivery_status.error = action.payload
    },
    deleteDeliveryIdle: (state) => {
      state.delete_delivery_status.status = FETCH_STATUS_IDLE
    },
    editScheduleRequest: (state) => {
      state.edit_schedule_status.status = FETCH_STATUS_REQUEST
    },
    editScheduleSuccess: (state) => {
      state.edit_schedule_status.status = FETCH_STATUS_SUCCESS
    },
    editScheduleFailure: (state, action) => {
      state.edit_schedule_status.status = FETCH_STATUS_ERROR
      state.edit_schedule_status.error = action.payload
    },
    editScheduleIdle: (state) => {
      state.edit_schedule_status.status = FETCH_STATUS_IDLE
    },
    deleteScheduleRequest: (state) => {
      state.delete_schedule_status.status = FETCH_STATUS_REQUEST
    },
    deleteScheduleSuccess: (state) => {
      state.delete_schedule_status.status = FETCH_STATUS_SUCCESS
    },
    deleteScheduleFailure: (state, action) => {
      state.delete_schedule_status.status = FETCH_STATUS_ERROR
      state.delete_schedule_status.error = action.payload
    },
    deleteScheduleIdle: (state) => {
      state.delete_schedule_status.status = FETCH_STATUS_IDLE
    },
    closeScheduleTimeRequest: (state) => {
      state.close_schedule_time_status.status = FETCH_STATUS_REQUEST
    },
    closeScheduleTimeSuccess: (state) => {
      state.close_schedule_time_status.status = FETCH_STATUS_SUCCESS
    },
    closeScheduleTimeFailure: (state, action) => {
      state.close_schedule_time_status.status = FETCH_STATUS_ERROR
      state.close_schedule_time_status.error = action.payload
    },
    closeScheduleTimeIdle: (state) => {
      state.close_schedule_time_status.status = FETCH_STATUS_IDLE
    },
  },
})

export const scheduleState = (state) => state.schedule

const { actions, reducer } = userSlice

export const {
  getAllScheduleRequest,
  getAllScheduleSuccess,
  getAllScheduleFailure,
  getAllScheduleIdle,
  getScheduleRequest,
  getScheduleSuccess,
  getScheduleFailure,
  getScheduleIdle,
  createScheduleRequest,
  createScheduleSuccess,
  createScheduleFailure,
  createScheduleIdle,
  addDeliveryRequest,
  addDeliverySuccess,
  addDeliveryFailure,
  addDeliveryIdle,
  editScheduleRequest,
  editScheduleSuccess,
  editScheduleFailure,
  editScheduleIdle,
  deleteScheduleRequest,
  deleteScheduleSuccess,
  deleteScheduleFailure,
  deleteScheduleIdle,
  closeScheduleTimeRequest,
  closeScheduleTimeSuccess,
  closeScheduleTimeFailure,
  closeScheduleTimeIdle,
  deleteDeliveryRequest,
  deleteDeliverySuccess,
  deleteDeliveryFailure,
  deleteDeliveryIdle,
} = actions

export default reducer
