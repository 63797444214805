import { createSlice } from '@reduxjs/toolkit'
import {
  FETCH_STATUS_IDLE, FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_ERROR,
} from '../../../utils/request/status'

const initialState = {
  get_all_banner_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  edit_banner_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  edit_banner_position_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  banner_list: [],
}

const userSlice = createSlice({
  name: 'banner',
  initialState,
  reducers: {
    getAllBannerRequest: (state) => {
      state.get_all_banner_status.status = FETCH_STATUS_REQUEST
    },
    getAllBannerSuccess: (state, action) => {
      state.get_all_banner_status.status = FETCH_STATUS_SUCCESS
      state.banner_list = action.payload
    },
    getAllBannerFailure: (state, action) => {
      state.get_all_banner_status.status = FETCH_STATUS_ERROR
      state.get_all_banner_status.error = action.payload
    },
    getAllBannerIdle: (state) => {
      state.get_all_banner_status.status = FETCH_STATUS_IDLE
    },
    editAllBannerRequest: (state) => {
      state.edit_banner_status.status = FETCH_STATUS_REQUEST
    },
    editAllBannerSuccess: (state, action) => {
      state.edit_banner_status.status = FETCH_STATUS_SUCCESS
      state.banner_list = action.payload
    },
    editAllBannerFailure: (state, action) => {
      state.edit_banner_status.status = FETCH_STATUS_ERROR
      state.edit_banner_status.error = action.payload
    },
    editAllBannerIdle: (state) => {
      state.edit_banner_status.status = FETCH_STATUS_IDLE
    },
    editAllBannerPositionRequest: (state) => {
      state.edit_banner_position_status.status = FETCH_STATUS_REQUEST
    },
    editAllBannerPositionSuccess: (state, action) => {
      state.edit_banner_position_status.status = FETCH_STATUS_SUCCESS
      state.banner_list = action.payload
    },
    editAllBannerPositionFailure: (state, action) => {
      state.edit_banner_position_status.status = FETCH_STATUS_ERROR
      state.edit_banner_position_status.error = action.payload
    },
    editAllBannerPositionIdle: (state) => {
      state.edit_banner_position_status.status = FETCH_STATUS_IDLE
    },
  },
})

export const bannerState = (state) => state.banner

const { actions, reducer } = userSlice

export const {
  getAllBannerRequest,
  getAllBannerSuccess,
  getAllBannerFailure,
  getAllBannerIdle,
  editAllBannerRequest,
  editAllBannerSuccess,
  editAllBannerFailure,
  editAllBannerIdle,
  editAllBannerPositionRequest,
  editAllBannerPositionSuccess,
  editAllBannerPositionFailure,
  editAllBannerPositionIdle,
} = actions

export default reducer
