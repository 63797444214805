import React from 'react'
import ReactDOM from 'react-dom'
import './assets/styles/global-style.css'
import 'antd/dist/antd.css'
import { ThemeProvider } from 'styled-components'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import createStore from './store/store'
import * as serviceWorker from './serviceWorker'
import RouterPage from './routes/router'
import themeColor from './assets/styles/colors'

const store = createStore()

ReactDOM.render(
  <React.StrictMode>
      <ThemeProvider theme={themeColor}>
        <Provider store={store}>
            <Router>
                <RouterPage />
            </Router>
        </Provider>
      </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
