import { createSlice } from '@reduxjs/toolkit'
import {
  FETCH_STATUS_IDLE, FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_ERROR,
} from '../../../utils/request/status'
import dayjs from 'dayjs'

const calculateAge = (date) => {
  const currentDate = dayjs()
  return currentDate.diff(dayjs(date), 'year')
}

const initialState = {
  customer_list_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  edit_customer_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  totalDocs: undefined,
  totalPages: undefined,
  customer_list: [],
}

const userSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    getCustomerListRequest: (state) => {
      state.customer_list_status.status = FETCH_STATUS_REQUEST
    },
    getCustomerListSuccess: (state, action) => {
      state.customer_list_status.status = FETCH_STATUS_SUCCESS
      state.totalDocs = action.payload.totalDocs
      state.totalPages = action.payload.totalPages
      state.customer_list = action.payload.docs.map( customer => (
        {
          ...customer,
          age: calculateAge(customer.dob),
        }
      ))
    },
    getCustomerListFailure: (state, action) => {
      state.customer_list_status.status = FETCH_STATUS_ERROR
      state.customer_list_status.error = action.payload
    },
    getCustomerListIdle: (state) => {
      state.customer_list_status.status = FETCH_STATUS_IDLE
    },
    editCustomerRequest: (state) => {
      state.edit_customer_status.status = FETCH_STATUS_REQUEST
    },
    editCustomerSuccess: (state, action) => {
      state.edit_customer_status.status = FETCH_STATUS_SUCCESS
      state.customer_list = prepareEditCustomerList(state.customer_list, action.payload)
    },
    editCustomerFailure: (state, action) => {
      state.edit_customer_status.status = FETCH_STATUS_ERROR
      state.edit_customer_status.error = action.payload
    },
    editCustomerIdle: (state) => {
      state.edit_customer_status.status = FETCH_STATUS_IDLE
    },
  },
})

const prepareEditCustomerList = (oldCustomerList, editedCustomerData) => {
  const newCustomerList = oldCustomerList.map(customer => {
    if (customer._id === editedCustomerData._id) {
      return editedCustomerData
    }
    return customer
  })
  return newCustomerList
}

export const customerState = (state) => state.customer

const { actions, reducer } = userSlice

export const {
  getCustomerListRequest,
  getCustomerListSuccess,
  getCustomerListFailure,
  getCustomerListIdle,
  editCustomerRequest,
  editCustomerSuccess,
  editCustomerFailure,
  editCustomerIdle,
} = actions

export default reducer
