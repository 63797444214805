import { createSlice } from '@reduxjs/toolkit'
import {
  FETCH_STATUS_IDLE, FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_ERROR,
} from '../../../utils/request/status'

const initialState = {
  get_point_rate_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  update_point_rate_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  pointRate: undefined
}

const pointSlice = createSlice({
  name: 'point',
  initialState,
  reducers: {
    getPointRateRequest: (state) => {
      state.get_point_rate_status.status = FETCH_STATUS_REQUEST
    },
    getPointRateSuccess: (state, action) => {
      state.get_point_rate_status.status = FETCH_STATUS_SUCCESS
      state.pointRate = action.payload
    },
    getPointRateFailure: (state, action) => {
      state.get_point_rate_status.status = FETCH_STATUS_ERROR
      state.get_point_rate_status.error = action.payload
    },
    getPointRateIdle: (state) => {
      state.get_point_rate_status.status = FETCH_STATUS_IDLE
    },
    updatePointRateRequest: (state) => {
      state.update_point_rate_status.status = FETCH_STATUS_REQUEST
    },
    updatePointRateSuccess: (state, action) => {
      state.update_point_rate_status.status = FETCH_STATUS_SUCCESS
      state.pointRate = action.payload
    },
    updatePointRateFailure: (state, action) => {
      state.update_point_rate_status.status = FETCH_STATUS_ERROR
      state.update_point_rate_status.error = action.payload
    },
    updatePointRateIdle: (state) => {
      state.update_point_rate_status.status = FETCH_STATUS_IDLE
    },
  },
})

export const pointState = (state) => state.point

const { actions, reducer } = pointSlice

export const {
  getPointRateRequest,
  getPointRateSuccess,
  getPointRateFailure,
  getPointRateIdle,
  updatePointRateRequest,
  updatePointRateSuccess,
  updatePointRateFailure,
  updatePointRateIdle,
} = actions

export default reducer
