import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import userReducer from './slice/user'
import employeeReducer from './slice/employee'
import stockReducer from './slice/stock'
import menuReducer from './slice/menu'
import locationReducer from './slice/location'
import scheduleReducer from './slice/schedule'
import customerReducer from './slice/customer'
import bannerReducer from './slice/banner'
import fileReducer from './slice/file'
import posReducer from './slice/pos'
import appReducer from './slice/app'
import realtimeReducer from './slice/realtimeOrder'
import orderReducer from './slice/order'
import overviewReducer from './slice/overview'
import pointReducer from './slice/point'

const createStore = () => {
  const reducer = {
    app: appReducer,
    user: userReducer,
    employee: employeeReducer,
    stock: stockReducer,
    menu: menuReducer,
    location: locationReducer,
    schedule: scheduleReducer,
    customer: customerReducer,
    banner: bannerReducer,
    file: fileReducer,
    pos: posReducer,
    realtimeOrder: realtimeReducer,
    order: orderReducer,
    overview: overviewReducer,
    point: pointReducer
  }
  const middlewares = getDefaultMiddleware({
    serializableCheck: false,
  })
  const store = configureStore({
    reducer,
    middleware: middlewares,
    devTools: process.env.NODE_ENV !== 'production',
  })
  return store
}

export default createStore
