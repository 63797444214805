import { createSlice } from '@reduxjs/toolkit'
import {
  FETCH_STATUS_IDLE, FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_ERROR,
} from '../../../utils/request/status'

const initialState = {
  upload_file_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  delete_file_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  url: undefined,
}

const userSlice = createSlice({
  name: 'file',
  initialState,
  reducers: {
    uploadFileRequest: (state) => {
      state.upload_file_status.status = FETCH_STATUS_REQUEST
    },
    uploadFileSuccess: (state, action) => {
      state.upload_file_status.status = FETCH_STATUS_SUCCESS
      state.url = action.payload
    },
    uploadFileFailure: (state, action) => {
      state.upload_file_status.status = FETCH_STATUS_ERROR
      state.upload_file_status.error = action.payload
    },
    uploadFileIdle: (state) => {
      state.upload_file_status.status = FETCH_STATUS_IDLE
    },
    deleteFileRequest: (state) => {
      state.delete_file_status.status = FETCH_STATUS_REQUEST
    },
    deleteFileSuccess: (state, action) => {
      state.delete_file_status.status = FETCH_STATUS_SUCCESS
    },
    deleteFileFailure: (state, action) => {
      state.delete_file_status.status = FETCH_STATUS_ERROR
      state.delete_file_status.error = action.payload
    },
    deleteFileIdle: (state) => {
      state.delete_file_status.status = FETCH_STATUS_IDLE
    },
  },
})

export const fileState = (state) => state.file

const { actions, reducer } = userSlice

export const {
  uploadFileRequest,
  uploadFileSuccess,
  uploadFileFailure,
  uploadFileIdle,
  deleteFileRequest,
  deleteFileSuccess,
  deleteFileFailure,
  deleteFileIdle,
} = actions

export default reducer
