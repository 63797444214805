import { createSlice } from '@reduxjs/toolkit'
import {
  FETCH_STATUS_ERROR,
  FETCH_STATUS_IDLE,
  FETCH_STATUS_REQUEST,
  FETCH_STATUS_SUCCESS,
} from '../../../utils/request/status'

const initialState = {
  isLeftBarOpen: true,
  printerConnectStatus: FETCH_STATUS_IDLE,
  ePOSDevice: undefined,
  deviceObj: undefined,
  get_printer_ip_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  set_printer_ip_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  get_sms_credit_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  smsCredit: undefined,
  otpCredit: undefined,
  printerIpAddress: undefined,
  isIpChanged: false,
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setOpenLeftBar: (state, action) => {
      state.isLeftBarOpen = action.payload
    },
    setPrinterConnectionLoading: (state) => {
      state.printerConnectStatus = FETCH_STATUS_REQUEST
    },
    setPrinterConnectionSuccess: (state) => {
      state.printerConnectStatus = FETCH_STATUS_SUCCESS
      state.isIpChanged = false
    },
    setPrinterConnectionFail: (state) => {
      state.printerConnectStatus = FETCH_STATUS_ERROR
    },
    setPrinterEPOSDevice: (state, action) => {
      state.ePOSDevice = action.payload
    },
    setPrinterDeviceObj: (state, action) => {
      state.deviceObj = action.payload
    },
    getPrinterIpAddressRequest: (state) => {
      state.get_printer_ip_status.status = FETCH_STATUS_REQUEST
    },
    getPrinterIpAddressSuccess: (state, action) => {
      state.get_printer_ip_status.status = FETCH_STATUS_SUCCESS
      state.printerIpAddress = action.payload.ip
    },
    getPrinterIpAddressFailure: (state, action) => {
      state.get_printer_ip_status.status = FETCH_STATUS_ERROR
      state.get_printer_ip_status.error = action.payload
    },
    getPrinterIpAddressIdle: (state) => {
      state.get_printer_ip_status.status = FETCH_STATUS_IDLE
    },
    setPrinterIpAddressRequest: (state) => {
      state.set_printer_ip_status.status = FETCH_STATUS_REQUEST
    },
    setPrinterIpAddressSuccess: (state, action) => {
      state.set_printer_ip_status.status = FETCH_STATUS_SUCCESS
      state.printerIpAddress = action.payload.ip
      state.isIpChanged = true
    },
    setPrinterIpAddressFailure: (state, action) => {
      state.set_printer_ip_status.status = FETCH_STATUS_ERROR
      state.set_printer_ip_status.error = action.payload
    },
    setPrinterIpAddressIdle: (state) => {
      state.set_printer_ip_status.status = FETCH_STATUS_IDLE
    },
    getSMSCreditRequest: (state) => {
      state.get_sms_credit_status.status = FETCH_STATUS_REQUEST
    },
    getSMSCreditSuccess: (state, action) => {
      state.get_sms_credit_status.status = FETCH_STATUS_SUCCESS
      state.smsCredit = action.payload.standard
      state.otpCredit = action.payload.corporate
    },
    getSMSCreditFailure: (state, action) => {
      state.get_sms_credit_status.status = FETCH_STATUS_ERROR
      state.get_sms_credit_status.error = action.payload
    },
    getSMSCreditIdle: (state) => {
      state.get_sms_credit_status.status = FETCH_STATUS_IDLE
    },
  },
})

export const appState = (state) => state.app

const { actions, reducer } = appSlice

export const {
  setOpenLeftBar,
  setPrinterConnectionLoading,
  setPrinterConnectionSuccess,
  setPrinterConnectionFail,
  setPrinterEPOSDevice,
  setPrinterDeviceObj,
  getPrinterIpAddressRequest,
  getPrinterIpAddressSuccess,
  getPrinterIpAddressFailure,
  getPrinterIpAddressIdle,
  setPrinterIpAddressRequest,
  setPrinterIpAddressSuccess,
  setPrinterIpAddressFailure,
  setPrinterIpAddressIdle,
  getSMSCreditRequest,
  getSMSCreditSuccess,
  getSMSCreditFailure,
  getSMSCreditIdle,
} = actions

export default reducer
